import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/tooltip/Tooltip.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/tooltip" storybook="https://workday.github.io/canvas-kit/?path=/story/components-popups-tooltip--default" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=21024-64092&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/8ce7c9c6a869ebf14e68265a48fc8578/536c7/component-anatomy-tooltip.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "10.81081081081081%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Tooltip",
              "title": "Image of a Tooltip",
              "src": "/static/8ce7c9c6a869ebf14e68265a48fc8578/50383/component-anatomy-tooltip.png",
              "srcSet": ["/static/8ce7c9c6a869ebf14e68265a48fc8578/1efb2/component-anatomy-tooltip.png 370w", "/static/8ce7c9c6a869ebf14e68265a48fc8578/50383/component-anatomy-tooltip.png 740w", "/static/8ce7c9c6a869ebf14e68265a48fc8578/536c7/component-anatomy-tooltip.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Container:`}</strong>{` A rectangular frame that holds the tooltip text.`}</li>
        <li parentName="ol"><strong parentName="li">{`Text:`}</strong>{` A description of what the element is in which the mouse pointer is hovering over.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`Tooltips provide relevant and descriptive information about an element.`}</li>
        <li parentName="ul">{`They appear when a user hovers over or interacts with the related element.`}</li>
        <li parentName="ul">{`Tooltip text should be descriptive, yet short and succinct.`}</li>
        <li parentName="ul">{`Tooltips should always be positioned next to the element it is connected to. The Tooltip’s
position will vary depending on where the element is in relation to the edge of the screen.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`Use Tooltips to help users differentiate between similar elements.`}</li>
        <li parentName="ul">{`Pair Tooltips with
`}<a parentName="li" {...{
            "href": "/components/buttons/button/"
          }}>{`icon only Primary/Secondary/Tertiary Buttons variants`}</a>{` to help
communicate the meaning or purpose of the icon.`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "how-tooltips-impact-the-accessible-experience"
      }}>{`How Tooltips Impact the Accessible Experience`}</h3>
      <p>{`Tooltip “hover” text is a great way to help users understand the intent of ambiguous icons, and
provide further detail about some elements in a design. A hovering interaction however, is not going
to be available to all users at all times. Mobile devices don’t support hovering interactions, and
desktop users may not be willing or able to use a pointing device to hover on an element.`}</p>
      <p>{`Alternatives to the hover interaction must be considered, and tooltip content must be displayed
visually on keyboard focus. Tooltips can be used for showing truncated text, but only in scenarios
where the keyboard can focus the component. Applying tooltips to truncated heading or paragraph text
will lead to accessibility problems.`}</p>
      <p>{`Refer to `}<a parentName="p" {...{
          "href": "/guidelines/content/ui-text/tooltips"
        }}>{`tooltips and toasts`}</a>{` for more information about writing tooltip
content.`}</p>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Tooltips must only be used on elements that can be focused with the keyboard. Sighted users must be
able to access the content in a tooltip without the use of a mouse.`}</p>
      <p>{`Tooltips must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: focuses an element, tooltip displays when element is focused`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Esc`}</inlineCode>{`: dismiss tooltip`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Tooltips must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`The text content of the tooltip as the name of an element (default)`}</li>
        <li parentName="ul">{`The text content of the tooltip as an element’s description (describe)`}</li>
        <li parentName="ul">{`Nothing, when the tooltip text content matches text content already on screen. (muted)`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <ul>
        <li parentName="ul">{`Specify which tooltip variant should be used: default, describe, or muted.`}</li>
      </ul>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`[Included in component]`}{` Default tooltips will set an `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` string to the element.`}</li>
        <li parentName="ul">{`[Included in component]`}{` Describe variant will instead set an `}<inlineCode parentName="li">{`aria-describedby`}</inlineCode>{` attribute to the
element, referencing an `}<inlineCode parentName="li">{`id`}</inlineCode>{` of the tooltip text content.`}</li>
        <li parentName="ul">{`[Included in component]`}{` Muted variant will be hidden from screen readers and other assistive
technology.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <p>{`Keep tooltips brief. Tooltips should not text wrap or span more than a single line. Try to keep
Tooltips understandable and clear for users by adding context to the element through simple
combinations of nouns and verbs, such as “Add Item” over a Plus icon or “Close” over an X icon. For
more information on writing Tooltip text, see the `}<a parentName="p" {...{
          "href": "/guidelines/content/ui-text/tooltips"
        }}>{`Tooltips and Toasts`}</a>{`
section of the content style guide.`}</p>
      <SideBySide mdxType="SideBySide">
        <Suggestion status="do" guidance="Use simple language and avoid text wrapping for Tooltip text." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "400px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/29aa80fadb7f806b3df0fed7c1e6c347/e17e5/tooltip-do.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "40%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAvElEQVR42q2SSwqEMBBEPY66dRNwIYgQ/G1CTqIgid4gZ9DruPc8Ymrohhky4CwGLCiabtIvFEmEQN57XNf1l2knVISHxUC6iXQcB6ZpwjzPsNZyJS/Lwr0x5lNpPo4j9n1HyGDgeZ7crOuKLMvQdR3KsmQXRYE8z1HXNdq25Vnf93wmTVM45xAyvoDbtiFJEmiteamqKgYJIdA0Dc8IKKWEUgpxHN8Df0V+myJTzDAy1WEY7iM//ihPfpsXVTgabF+K+u4AAAAASUVORK5CYII=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Tooltip example with one line, showing what to do when designing a tooltip",
                  "title": "Tooltip example with one line, showing what to do when designing a tooltip",
                  "src": "/static/29aa80fadb7f806b3df0fed7c1e6c347/e17e5/tooltip-do.png",
                  "srcSet": ["/static/29aa80fadb7f806b3df0fed7c1e6c347/1efb2/tooltip-do.png 370w", "/static/29aa80fadb7f806b3df0fed7c1e6c347/e17e5/tooltip-do.png 400w"],
                  "sizes": "(max-width: 400px) 100vw, 400px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
        <Suggestion status="error" guidance="Use confusing language such as present perfect tense or include unnecessary words when ‘expense submitted’ provides enough context for the action." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "400px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/64c4856b9ab44c3aa0649bd19729cb9d/e17e5/tooltip-dont.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "40%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABHUlEQVR42q2Sz6qCUBDG2/oAIS4iJDVo6ULJNvmnwBYlFeZGfAdb5B8QdafL6Gl6gF7K4rs4kPd623bgx8yZAx/fzJwBvnwGr9eLksfjgfP5jDRNO+I47kiSpKv9jWEY4n6/k0arNWiahi5VVUGSJNi2DVVVCcMwiOVyicViAU3TYFkWFEXBarUiRFHE5XIhjefz+St4vV4xnU7hui7W6zVM08TpdMLhcIDjOPA8j+pBEGCz2dCb7/uYz+coiuLT4e12w2w2w/F4JIHdbkf5druFLMsYj8fkhud5CILQxeFw2An2HNZ1jclkgv1+T050Xaf2W9pRsCwLjuN6jEYjMAzTb/n/Utrhv5cQRRGRZRnyPP+gLEsS6y3l29/mB+gawcWQL4ToAAAAAElFTkSuQmCC')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Tooltip example with two lines, showing what to avoid when designing a tooltip",
                  "title": "Tooltip example with two lines, showing what to avoid when designing a tooltip",
                  "src": "/static/64c4856b9ab44c3aa0649bd19729cb9d/e17e5/tooltip-dont.png",
                  "srcSet": ["/static/64c4856b9ab44c3aa0649bd19729cb9d/1efb2/tooltip-dont.png 370w", "/static/64c4856b9ab44c3aa0649bd19729cb9d/e17e5/tooltip-dont.png 400w"],
                  "sizes": "(max-width: 400px) 100vw, 400px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
      </SideBySide>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      